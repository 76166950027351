<template>
  <div class="wrapper mb-10">
    <div class="grid grid-cols-1 lg:grid-cols-3 gap-6">
      <div class="lg:col-span-2">
        <h2 class="text-2xl md:text-3xl font-bold text-center dark:text-green-400 my-10">
          راهنمای استفاده گرندبیتکس
        </h2>
      </div>
    </div>

    <div class="main-content grid grid-cols-1 lg:grid-cols-3 gap-6">
      <article class="lg:col-span-2">
        <div class="leading-relaxed bg-gray-50 dark:bg-gray-800 shadow rounded p-4 md:p-6 space-y-10">
          <!--#region Where to start-->
          <section class="space-y-6">
            <h4 id="where-to-start"
                class="title">
              از کجا شروع کنم؟
            </h4>

            <div class="space-y-4">
              <p>
                برای شروع کار با گرندبیتکس ابتدا مراحل زیر را تکمیل نمائید:
              </p>

              <ul class="list-disc list-inside space-y-1">
                <li>
                  ایجاد حساب کاربری با ایمیل معتبر
                </li>

                <li>
                  انجام فرآیند احراز هویت
                </li>

                <li>
                  انجام معامله خرید یا فروش
                </li>
              </ul>

              <p>
                <span class="font-bold">تذکر: </span>

                <span> پیش از ثبت‌نام حتما صفحه‌ی </span>

                <router-link :to="{ name: 'Policy' }"
                             class="text-blue-600 hover:underline">
                  قوانین و مقررات
                </router-link>

                <span> سایت را مطالعه فرمائید. در صورتی که با همه‌ی قوانین و مقررات موافق بودید، اقدام به ثبت‌نام فرمائید.</span>
              </p>
            </div>
          </section>
          <!--#endregion Where to start-->

          <GradientDivider class="divider" />

          <!--#region Register - Verification-->
          <section class="space-y-6">
            <h4 id="register-verification"
                class="title">
              نحوه ثبت‌نام و احراز هویت
            </h4>

            <div class="space-y-4">
              <p>
                <span>برای شروع ابتدا از منو بالای وبسایت بر روی گزینه </span>

                <span class="font-bold"> ورود | ثبت‌نام </span>

                <span> کلیک کنید. صفحه‌ی زیر برای شما باز می‌شود.</span>
              </p>

              <img :src="imgSrc('01.jpg')"
                   alt="ورود به حساب کاربری | گرندبیتکس"
                   class="screenshot screenshot-sm">

              <p>
                <span>با کلیک بر روی گزینه‌ی </span>
                <span class="font-bold"> ثبت نام رایگان </span>
                <span>اطلاعات خود را وارد کنید و پس از کلیک بر روی گزینه</span>
                <span class="font-bold"> عضویت در گرندبیتکس </span>
                <span> ابتدا یک کد فعالسازی برای شماره همراه شما ارسال می‌شود، سپس کد ارسالی را در بخش </span>
                <span class="font-bold"> کد احراز هویت </span>
                <span> وارد کنید تا حساب کاربری شما ایجاد می‌شود.</span>
              </p>

              <img :src="imgSrc('02.jpg')"
                   alt="ثبت‌نام در صرافی | گرندبیتکس"
                   class="screenshot screenshot-sm">

              <p>
                حال برای اینکه بتوانید از صرافی ارزی خریداری کنید یا ارزی را به صرافی بفروشید، لازم است مراحل احراز هویت
                را کامل نمائید. برای این کار لازم است بر روی گزینه
                <span class="font-bold"> ارسال مدارک و احراز هویت </span>
                کلیک و اطلاعات خود را تکمیل نمائید.
              </p>

              <img :src="imgSrc('03.jpg')"
                   alt="احراز هویت در صرافی | گرندبیتکس"
                   class="screenshot">

              <p>
                در این بخش نیاز است که عکس سلفی مطابق توضیحات ارائه‌شده را آپلود نمائید.
              </p>

              <img :src="imgSrc('04.jpg')"
                   alt="نمونه عکس سلفی احرازهویت | گرندبیتکس"
                   class="screenshot">

              <p>
                حال با کلیک بر روی دکمه‌ی
                <span class="font-bold"> ارسال مدارک، </span>
                درخواست احراز هویت شما ارسال می‌گردد. سپس منتظر بمانید تا عکس و
                اطلاعات هویتی واردشده‌ی شما توسط کارشناسان ما تائید گردد. پس از دریافت پیام تائید حال شما به خانواده
                بزرگ
                گرندبیتکس محلق شده‌اید.
              </p>

              <p>
                در گام بعدی شما می‌توانید از قسمت
                <span class="font-bold"> کارت‌های بانکی </span>
                اقدام به
                <span class="font-bold"> افزودن کارت بانکی </span>
                و تکمیل اطلاعات حساب کاربری خود نمایید. پس از ثبت اطلاعات درخواست شما جهت اعتبارسنجی و تائید برای
                کارشناسان ما ارسال می‌گردد.
              </p>

              <img :src="imgSrc('05.jpg')"
                   alt="ثبت کارت بانکی کاربر | گرندبیتکس"
                   class="screenshot screenshot-lg">
            </div>
          </section>
          <!--#endregion Register - Verification-->

          <GradientDivider class="divider" />

          <!--#region Sell/Buy-->
          <div class="space-y-6">
            <h4 id="sell-buy"
                class="title">
              خرید و فروش ارز دیجیتال
            </h4>

            <!--Buy-->
            <div class="space-y-4">
              <h6 class="font-bold">
                نحوه‌ی خرید ارز دیجیتال به چه صورت است؟
              </h6>

              <p>
                جهت خرید ارز دیجیتال می‌توانید از منوی خدمات رمز ارزها و یا از تب رمز ارزها در نوار بالای صفحه، ارز
                مورد نظر خود را انتخاب نموده و سپس مطابق صفحه‌ی زیر، گزینه‌ی خرید ارز را انتخاب نمائید.
              </p>

              <img :src="imgSrc('06.jpg')"
                   alt="خرید و فروش ارز دیجیتال در صرافی | گرندبیتکس"
                   class="screenshot">

              <p>
                قیمت ارز در لحظه به شما نمایش داده می‌شود. حال کافی است مقدار ارز موردنظر خود را وارد نموده و سپس با
                کلیک
                بر روی گزینه‌ی
                <span class="font-bold"> ثبت و پرداخت </span>
                اقدام به پرداخت نموده و منتظر بمانید تا مقدار ارز موردنظر به کیف‌پول شما اضافه گردد.
              </p>

              <img :src="imgSrc('07.jpg')"
                   alt="خرید بیت‌کوین از صرافی | گرندبیتکس"
                   class="screenshot">

              <p>
                <span class="font-bold">تذکر: </span>
                در صورتی که موجودی شما کافی نیست، روی گزینه
                <span class="font-bold"> کیف پول تومانی </span>
                کلیک کنید و حساب خود را به میزان مورد نظر شارژ نمائید.
              </p>

              <p>
                جهت شارژ کیف پول تومانی خود دو راه برای شما وجود دارد.
              </p>

              <img :src="imgSrc('08.jpg')"
                   alt="انواع روش شارژ کیف پول ریالی | گرندبیتکس"
                   class="screenshot">

              <p>
                در صورتی که گزینه
                <span class="font-bold"> شارژ کیف پول با پرداخت آنلاین </span>
                را انتخاب نمودید، با وارد نمودن مبلغ موردنظر بر روی گزینه
                <span class="font-bold"> ثبت و پرداخت </span>
                کلیک کنید.
              </p>

              <img :src="imgSrc('09.jpg')"
                   alt="شارژ کیف‌پول ریالی با پرداخت آنلاین | گرندبیتکس"
                   class="screenshot">

              <p>
                در گام بعدی با انتخاب کارت بانکی ثبت شده وارد درگاه پرداخت خواهید شد.
              </p>

              <img :src="imgSrc('10.jpg')"
                   alt="پرداخت از طریق درگاه با کارت‌بانکی ثبت‌شده | گرندبیتکس"
                   class="screenshot">

              <p>
                <span class="font-bold">تذکر: </span>
                دقت نمائید اطلاعات کارت وارد شده در مرحله پرداخت با کارت بانکی انتخاب شده در مرحله قبل یکسان باشد.
              </p>

              <p>
                در روش دوم برای شارژ کیف پول تومانی خود، می‌توانید گزینه
                <span class="font-bold"> شارژ کیف پول با ارائه فیش بانکی </span>
                را انتخاب نمائید.
              </p>

              <img :src="imgSrc('11.jpg')"
                   alt="شارژ کیف‌پول با ارائه فیش بانکی | گرندبیتکس"
                   class="screenshot">

              <p>
                در این مرحله نیز تنها کافی است با وارد کردن مبلغ واریز شده و آپلود فیش واریزی، اقدام به تکمیل اطلاعات و
                <span class="font-bold"> ثبت درخواست </span>
                خود نمائید.
              </p>

              <img :src="imgSrc('12.jpg')"
                   alt="ثبت درخواست شارژ کیف‌پول با ارائه فیش بانکی | گرندبیتکس"
                   class="screenshot">

              <p>
                درخواست شما برای کارشناسان ما جهت اعتبارسنجی فیش واریزی ارسال می گردد و پس از تائید، مبلغ مورد نظر در
                کیف
                پول تومانی شما شارژ می‌گردد.
              </p>
            </div>

            <!--Sell-->
            <div class="space-y-4">
              <h6 class="font-bold">
                نحوه‌ی فروش ارز دیجیتال به چه صورت است؟
              </h6>

              <p>
                جهت فروش ارز دیجیتال می‌توانید از منوی خدمات رمز ارزها و یا از تب رمز ارزها در نوار بالای صفحه، ارز
                موردنظر خود را انتخاب نموده و سپس مطابق صفحه‌ی زیر، گزینه‌ی فروش ارز را انتخاب نمائید.
              </p>

              <img :src="imgSrc('13.jpg')"
                   alt="فروش ارز دیجیتال در صرافی | گرندبیتکس"
                   class="screenshot">

              <p>
                قیمت ارز در لحظه به شما نمایش داده می‌شود. حال کافی است مقدار ارز مورد نظر خود را وارد نموده و سپس با
                کلیک
                بر روی گزینه‌ی
                <span class="font-bold"> ثبت درخواست </span>
                اقدام به فروش میزان ارز انتخابی خود نمائید.
              </p>

              <img :src="imgSrc('14.jpg')"
                   alt="ثبت درخواست فروش بیت‌کوین به صرافی | گرندبیتکس"
                   class="screenshot">

              <p>
                حال منتظر بمانید تا درخواست شما توسط کارشناسان ما تائید شود، پس از تائید تراکنش در این مرحله حساب ریالی
                شما شارژ خواهد شد.
              </p>
            </div>
          </div>
          <!--#endregion Sell/Buy-->

          <GradientDivider class="divider" />

          <!--#region Withdraw/Deposit-->
          <div class="space-y-6">
            <h4 id="withdraw-deposit"
                class="title">
              واریز و برداشت ارز دیجیتال در کیف پول
            </h4>

            <!--Deposit-->
            <div class="space-y-4">
              <h6 class="font-bold">
                نحوه‌ی واریز ارز دیجیتال به چه صورت است؟
              </h6>

              <p>
                برای انجام عملیات واریز ارز دیجیتال به
                <span class="font-bold"> کیف پول </span>
                گرندبیتکس شما در ابتدا وارد منوی کیف پول شده و سپس با
                انتخاب ارز مورد نظر گزینه
                <span class="font-bold"> واریز </span>
                را انتخاب نمائید.
              </p>

              <img :src="imgSrc('15.jpg')"
                   alt="گزینه واریز در کیف‌پول کاربر | گرندبیتکس"
                   class="screenshot screenshot-lg">

              <p>
                در این قسمت آدرس کیف پول گرندبیتکس خود را جهت انجام اقدامات لازم در کیف پول مبداء خود، کپی نمائید.
              </p>

              <img :src="imgSrc('16.jpg')"
                   alt="ثبت درخواست واریز به کیف‌پول | گرندبیتکس"
                   class="screenshot screenshot-lg">

              <p>
                پس از انتقال ارز، شماره تراکنش خود را از کیف پول مبداء کپی نمائید. سپس با درج کد txID آن در فیلد مربوط
                به
                شماره تراکنش و همچنین انتخاب شبکه‌ی مناسب جهت انتقال روی دکمه
                <span class="font-bold"> ثبت درخواست </span>
                کلیک نمائید.
              </p>

              <p>
                <span class="font-bold">توجه: </span>
                شما می‌توانید به جای کپی نمودن آدرس والت خود از QR code والت خود نیز استفاده نمائید.
              </p>
            </div>

            <!--Withdraw-->
            <div class="space-y-4">
              <h6 class="font-bold">
                نحوه‌ی برداشت ارز دیجیتال به چه صورت است؟
              </h6>

              <p>
                برای انجام عملیات برداشت ارز دیجیتال از کیف‌پول گرندبیتکس شما در ابتدا وارد منوی کیف پول شده و سپس با
                انتخاب ارز موردنظر گزینه
                <span class="font-bold"> برداشت </span>
                را انتخاب نمائید.
              </p>

              <img :src="imgSrc('17.jpg')"
                   alt="گزینه برداشت از کیف‌پول کاربر | گرندبیتکس"
                   class="screenshot screenshot-lg">

              <p>
                در این قسمت کافی است با وارد نمودن آدرس کیف پول مقصد، انتخاب شبکه انتقالی مناسب، و همچنین درج مقدار
                درخواستی خود جهت برداشت ارز موردنظر روی گزینه
                <span class="font-bold"> ثبت درخواست </span>
                کلیک نمائید.
              </p>

              <img :src="imgSrc('18.jpg')"
                   alt="ثبت درخواست برداشت از کیف‌پول | گرندبیتکس"
                   class="screenshot screenshot-lg">

              <p>
                درخواست شما برای کارشناسان ما ارسال می‌گردد و پس از تائید مقدار ارز درخواستی شما به کیف پول مقصد انتقال
                می‌یابد.
              </p>

              <p>
                <span class="font-bold">تذکر: </span>
                از صحت آدرس کیف پول مقصد خود اطمینان حاصل نمائید، در صورت وارد کردن آدرس اشتباه، مسئولیت به عهده‌ کاربر
                خواهد بود.
              </p>
            </div>
          </div>
          <!--#endregion Withdraw/Deposit-->

          <GradientDivider class="divider" />

          <!--#region Referral Code-->
          <div class="space-y-4">
            <h4 id="referral-marketing"
                class="title">
              طرح دعوت از دوستان در گرندبیتکس
            </h4>

            <p>
              شما می‌توانید از منوی کسب درآمد، کد و یا لینک دعوت خود را برای دوستانتان ارسال نمائید و از طرح درآمدزایی
              گرندبیتکس بهره‌مند شوید. به ازای دعوت هریک از دوستانتان، 0.2 درصد از تراکنش (خرید یا فروش) وی به حساب شما
              واریز می‌گردد.
            </p>

            <img :src="imgSrc('19.jpg')"
                 alt="طرح دعوت از دوستان | گرندبیتکس"
                 class="screenshot screenshot-lg">
          </div>
          <!--#endregion Referral Code-->
        </div>
      </article>

      <aside id="sidebar"
             class="hidden lg:block sidebar">
        <nav class="sidebar__inner">
          <ul v-if="sections.length"
              class="flex flex-col list-none">
            <li v-for="(section, index) in sections"
                :key="index"
                :class="{ 'bg-green-200 text-green-900': isCurrent(section.id) }"
                class="rounded-sm px-4 py-2">
              <a :href="`#${section.id}`"
                 @click.prevent="jumpTo(section.id)">{{ section.innerText }}</a>
            </li>
          </ul>
        </nav>
      </aside>
    </div>
  </div>
</template>

<script>
import StickySidebar from "sticky-sidebar-v2/dist/sticky-sidebar";
import GradientDivider from "@/components/utilities/GradientDivider";

export default {
  components: {
    GradientDivider,
  },
  data() {
    return {
      sections: [],
      currentSection: "",
      offset: 80,
    };
  },
  mounted() {
    this.initStickySidebar();
    this.initNavigations();
    this.handleLinkHash();
  },
  methods: {
    imgSrc(imgName) {
      try {
        return require(`@/assets/images/guide/${imgName}`);
      } catch (e) {
        return require(`@/assets/images/default.jpg`);
      }
    },
    initStickySidebar() {
      new StickySidebar("#sidebar", {
        containerSelector: ".main-content",
        innerWrapperSelector: ".sidebar__inner",
        topSpacing: 100,
        bottomSpacing: 0,
      });
    },
    initNavigations() {
      const sectionElements = document.getElementsByClassName("title");

      this.sections = Array.from(sectionElements);

      this.handleScroll();
    },
    handleLinkHash() {
      const hash = window.location.hash;

      if (!hash.length)
        return;

      const id = hash.substring(1);
      window.addEventListener("load", () => {
        this.jumpTo(id);
      });
    },
    handleScroll() {
      window.addEventListener("scroll", () => {
        this.sections.forEach((section) => {
          if (pageYOffset >= (section.offsetTop - this.offset))
            this.currentSection = section.id;
        });
      });
    },
    jumpTo(id) {
      const section = document.getElementById(id);

      if (!section)
        return;

      const top = section.offsetTop - this.offset;

      history.replaceState(null, null, `#${id}`);

      window.scrollTo({
        top,
        behavior: "smooth",
      });
    },
    isCurrent(id) {
      return id === this.currentSection;
    },
  },
};
</script>

<style scoped>
.title {
  @apply text-center text-lg font-black
}

.divider {
  @apply h-1 from-green-50 via-green-200 to-green-50
}

.screenshot {
  @apply w-full md:w-1/2 rounded shadow !my-8 mx-auto
}

.screenshot-sm {
  @apply md:w-1/3
}

.screenshot-lg {
  @apply md:w-full
}
</style>
